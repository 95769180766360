<template>
  <Data
    :shadow="shadow"
    :showMenu="showMenu"
    :panelLoad="panelLoad"
    is-dashboard
  >
    <div class="dashboard-documents__header">
      <h1 class="dashboard-documents__heading">
        <span
          v-if="createDoc && canCreateFormContract"
          class="create-new__back-button"
          @click.prevent.stop="createDoc = false"
        >
          <IcoBack /> {{ $t('general.back') }}
        </span>

        {{ title }}
      </h1>
    </div>

    <template v-if="canCreateFormContract">
      <Storage
        :isAttachment="!!activeContractId"
        :panelLoad="panelLoad"
        @change="(page) => this.$emit('change', page)"
        @docClick="createDoc = true"
      />

      <TemplatesTable
        v-if="!disableForms"
        class="create-templates-wrapper"
        :templates="favouriteTemplates"
        :title="$t('dashboard.favourite_patterns')"
        :noSearch="true"
        @showAllTemplates="showAllTemplates"
      />
    </template>

    <DocumentUploader
      v-else
      :create-contract="true"
      :contract-id="activeContractId"
      :workspace-id="activeWorkspaceId"
    />
  </Data>
</template>

<script>
import { mapGetters } from 'vuex';
import { TemplateService } from '@/services/TemplateService.js';
import DocumentUploader from '@/features/contract/components/DocumentUploader';
import TemplatesTable from '@/features/dashboard/components/TemplatesTable';

export default {
  name: 'CreateNew',
  components: {
    Storage: () => import('@/components/Storage.vue'),
    IcoBack: () => import('@/components/svg/IcoBack.vue'),
    DocumentUploader,
    TemplatesTable,
  },
  props: {
    disableForms: {
      type: Boolean,
      default: false,
    },
    panelLoad: {
      required: false,
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      favouriteTemplates: null,
      shadow: this.$route.name === 'createNewAttachment',
      showMenu: this.$route.name !== 'createNewAttachment',
      createDoc: false,
    };
  },
  computed: {
    ...mapGetters({
      profile: 'profile',
      workspaceById: 'workspaceById',
    }),
    activeContractId() {
      return this.$route.params?.contract_id;
    },
    activeWorkspace: function () {
      return this.workspaceById(this.activeWorkspaceId);
    },
    activeWorkspaceId() {
      return this.$route?.params?.workspace_id || localStorage.getItem('workspace_id');
    },
    canCreateFormContract() {
      return this.activeWorkspace?.settings?.contracts?.can_create_form_contract || false;
    },
    title() {
      return this.$t(`contract.${this.activeContractId ? 'attach_document' : 'new_contract'}`);
    },
  },
  created() {
    TemplateService.getWorkspaceTemplates(this.activeWorkspaceId, true).then((response) => {
      this.favouriteTemplates = response?.favourite;
      window.dataLayer.push({
        event: 'contract_new',
      });
    });
  },
  methods: {
    redirectToTemplates() {
      this.$router.push({
        name: 'template',
        params: {
          workspace_id: this.activeWorkspaceId,
        },
      });
    },
    showAllTemplates() {
      if (this.panelLoad) {
        this.showContractTemplates();
      } else {
        this.redirectToTemplates();
      }
    },
    showContractTemplates() {
      this.$emit('change', 'showContractTemplates');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@/assets/sass/mixins';

.create-new__back-button {
  font-size: 16px;
  font-weight: bold;
  color: #5e239e;
  position: absolute;
  top: 10px;
  left: 0;
  cursor: pointer;

  @include md {
    top: 35px;
    left: 30px;
  }
}

.create-templates-wrapper {
  max-width: 845px;
}
</style>
