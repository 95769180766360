import { internalApiRequest } from '@/shared/services/apiService';
import { defineStore } from 'pinia';

export const useContactsStore = defineStore('contacts', {
  state: () => ({
    list: [],
    savedContactsIds: [],
  }),
  getters: {
    contactsList: (state) => {
      return state.list;
    },
    contactsIdsForContracts: (state) => {
      return state.savedContactsIds;
    },
    isBulkSend: (state) => {
      return state.savedContactsIds?.length > 1;
    },
  },
  actions: {
    fetchContactsList({ workspaceId, groupsIds, searchQuery, pagination, loadMetadata = true }) {
      return internalApiRequest({
        method: 'POST',
        path: `api/v1/workspaces/${workspaceId}/contacts`,
        data: {
          workspace_id: workspaceId,
          groups_ids: groupsIds,
          search_query: searchQuery,
          pagination: {
            limit: pagination?.limit || 10,
            offset: pagination?.offset || 0,
          },
          load_metadata: loadMetadata,
        },
      }).then((resp) => {
        this.list = resp.data;

        return resp;
      });
    },
    saveContactsIds(contactsIds) {
      this.savedContactsIds = contactsIds;
    },
    resetContactsList() {
      this.list = [];
    },
    resetSavedContactsIds() {
      this.savedContactsIds = [];
    },
  },
});
